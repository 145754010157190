const dsm = require('./dsm');

const staticFilterPillColor = '#7266BA';

const colors = {
    primaryColor: '#007cbe',
    availabilityPeriodsModalBorder: '#e3e3e3',
    availabilityPill_Available: dsm.feijoa,
    availabilityPill_NotAvailable: dsm.mauvelous,
    availabilityPill_PartiallyAvailable: dsm.anakiwa,
    availabilityPillFont_Available: dsm.greenKelp,
    availabilityPillFont_NotAvailable: dsm.cocoaBean,
    availabilityPillFont_PartiallyAvailable: dsm.tundora,
    availabilityPeriod: dsm.tundora,
    backButton: '#FF3333',
    backButtonPressed: '#e61a1a',
    backgroundRoundIcon: '#f2f2f2',
    backgroundRoundIconActive: '#e4e4e4',
    black: '#000',
    borderInput: '#777',
    borderInputFocus: '#aaaebb',
    borderInputLight: '#d4d9e2',
    borderStroke: '#A3A3A3',
    boxShadow: 'rgba(119, 119, 119, .25)',
    buttonBoxShadow: 'rgba(0, 0, 0, .25)',
    calendarTextColor: '#282828',
    calendarButtonBorder: '#dce0df',
    calendarDayColor: '#cacccd',
    calendarDayRange: '#1ABEFF',
    calendarSelectedDayBorder: '#50cdff',
    calendarDisabledDayBorder: '#e4e7e7',
    calendarDisabledSelected: '#bfe6ff',
    cancelButton: '#F16363',
    closeButton: ' rgb(81,89,103)',
    cocoBean: '#492020',
    containerBoxShadow: 'rgba(0, 0, 0, 0.1)',
    criteriaFilterPill: '#68724C',
    criteriaFilterPillNot: '#A80000',
    customScreensFilterPill: '#515151',
    contextualFilterPill: '#00125a',
    dateRangeBackground: '#b6ecff',
    boxShadowMenu: '#b6bdc2',
    defaultText: '#404040',
    dialogTitle: '#4F4F4F',
    dialogHeaderBackground: '#F6F6F6',
    disable: '#CECECE',
    disabled: '#d9dbdc',
    disabledGray: '#898989',
    disabledButtonBackground: '#CECECE',
    disabledIcon: '#CECECE',
    disableUnselectedRadio: '#edeef0',
    domainSettingsLabel: '#8c94b0',
    domainSettingsHelpText: '#A8A8A8',
    radioColor: '#696969',
    radioHover: 'rgba(170, 174, 187, 0.5)',
    dropdownShadow: 'rgba(0,0,0,0.5)',
    errorBackground: '#ff8533',
    errorBackgroundToast: '#F07A7B',
    pinkErrorBackground: '#F0DDD1',
    errorTextColorRed: '#B00020',
    errorTextToast: '#492020',
    filterBarStroke: '#e8e8e8',
    fillRateGridBackground: dsm.wildSand,
    fillRateColumnSelector: '#2d6ec0',
    flightPickerBorder: '#dce0df',
    tagColor: dsm.froly,
    focusOutline: '#3277C0',
    focusBoxShadow: 'rgba(50, 119, 192, 0.5)',
    goalSeekAchievability: '#EB9896',
    greenOk: '#00CC33',
    greenStatic: '#246358',
    greyF4: '#F4F4F4',
    textGrey: '#657787',
    textLabel: '#2C2C2C',
    greenKelp: '#36492a',
    gridHeaderBackground: '#eaeaea',
    gridHeaderBorder: '#d4d9e2',
    gridHover: '#f3f7fc',
    gridRowSpacingBorder: '#f8f8f8',
    iconActiveDanger: '#f21b23',
    iconDefaultColor: '#bcbcbc',
    iconFillColor: '#282828',
    iconDefaultThemeColor: '#007cbe',
    inactiveRadio: '#ebebeb',
    inputBorder: 'rgba(163,163,163,0.48)',
    defaultGridTextColor: '#282828',
    defaultGridHeaderRowBackground: '#f2f2f2',
    defaultGridRowBackground: '#FFFFFF',
    selectedRowBackground: '#EDF6FC',
    defaultGridSelectedRowBorder: '#E5E5E5',
    defaultGridBorderColor: '#D4D9E2',
    fillRateBar: '#0f97dd',
    keywordFilterPill: '#4F717F',
    loaderBackground: 'rgba(0, 0, 0, 0.3)',
    loaderTextColor: '#fff',
    menuButtonHover: 'rgba(128,46,248,0.1)',
    mantis: '#93cb73',
    modalOverlay: 'rgba(30, 30, 30, 0.8)',
    navBorder: 'rgba(151, 151, 151, 0.2)',
    networkBooked: '#0389ce',
    noItemsBookedText: '#b6bdc2',
    notActiveText: '#808080',
    onion: '#30230e',
    optionHover: '#d7eefd',
    gridPopupBackground: '#f4f4f4',
    pliDetailsLabel: '#949494',
    pliStatusBlue: '#077F86',
    pliStatusBlueGreen: '#00A18A',
    pliStatusGreen: '#0C8607',
    pliStatusGrey: '#7D7D7D',
    pliStatusOrange: '#FF4F00',
    pliStatusRed: '#860707',
    dialogLabel: '#444444',
    dialogText: '#303030',
    pliPreemptibleLabel: '#00AAF4',
    pliValuesLabel: '#575757',
    pliBlueAction: '#007bcb',
    pliBlueActionHover: '#eaf7ff',
    pliGreenAction: '#1d4e0ed9',
    pliGreenActionHover: '##1d4e0e1c',
    pliReleaseAll: '#00558c',
    pliReleaseAllHover: '#00558c14',
    pliProcessing: '#36ae9a',
    primary: '#00a8ff',
    primaryActiveBackground: '#0097e6',
    primaryActiveBorder: '#0086cc',
    primarybutton: '#4aa6f9',
    primarybuttonpressed: '#3b87c8',
    primaryButtonShadow: '1px 1px 2px rgba(0, 0, 0, 0.25)',
    progressBarOutline: '#cfcfcf',
    progressBarBackground: '#ddd',
    progressBarUnderperformColor: '#fc6471',
    progressBarOverperformColor: '#5cb443',
    proposalBuilderInputColor: '#596377',
    rajah: '#f5bf64',
    removeButton: '#AC0606',
    rowDetailsTextColor: '#657787',
    secondarybutton: '#4aa6f9',
    secondarybuttonpressed: '#3b87c8',
    secondarybuttonHoverDark: '#216DA5',
    selectBorder: '#d4d9e2',
    selectHoverBackground: '#e6f6fc',
    sidebarBackground: '#007CBE',
    staticAvailabilityStatusFilterPill: staticFilterPillColor,
    staticFaceNameFilterPill: staticFilterPillColor,
    staticFacesCount: '#007BBD',
    staticFilterTitle: '#4A657D',
    staticFilterBackground: '#5C7A94',
    staticFormatFilterPill: staticFilterPillColor,
    staticInventoryColor: '#03b592',
    staticSiteNameFilterPill: staticFilterPillColor,
    statusError: '#ed1111',
    statusErrorBackground: '#f9b6b8',
    statusOk: '#06cd30',
    activeState: '#73c246',
    inactiveState: '#404040',
    summaryBackground: '#F6FBFD',
    summaryBorder: '#E0E7EA',
    summaryBar: '#802ef8',
    summaryValue: '#337BC5',
    switchThumbDisabled: '#bdbdbd',
    switchTrackToggledEnabled: '#00CC32',
    switchTrackToggledDisabled: '#98bfa1',
    switchThumbToggledDisabled: '#efefef',
    successBackgroundToast: '#93CB73',
    successTextToast: '#36492A',
    textError: '#f21b23',
    textInput: '#08015d',
    textHighlight: '#BAD7FB',
    tooltipDarkBackground: 'rgba(0, 0, 0, 0.7)',
    tooltipDarkText: '#999',
    tooltipInfoBorder: '#999',
    tooltipInfoText: '#282828',
    uploaderIconColor: '#333',
    unselectedTabBorder: 'rgba(256, 256, 256, 0.50)',
    warningBackgroundToast: '#F5BF64',
    warningTextToast: '#30230E',
    white: '#fff',
    yellowPartial: '#9e9e21',
    categoryPillBackground: '#00486D',
    defaultFieldBorder: '#D2D2D2', // planned to be used for all inputs/fields etc.
    titleBarDropShadow: 'rgba(0, 0, 0, 0.14)',
    titleBarBackground: '#FBFBFB',
    errorText: '#FF3333',
    groupCount: '#EEC278',
    progressBarOverPerformColor: '#1d8506',
    progressBarBorderlineUnderPerform: '#ff6a00',
    progressBarUnderPerformColor: '#f90000',
    progressBarProjectedBarColor: '#b5b5b5',
    progressBarTotalBarColor: '#eaeaea',
    progressBarAvailabilityCheck: '#006DA7',
    progressBarAvailabilityCheckBackground: '#E1E0EE',
    nextStepAction: '#2CAA43',
    rejectAction: '#FF0101',
    formLabel: '#404040',
    tagline: '#929197',
    primaryColorHover: '#def4ff',
    emptySharedGoalInfo: '#636268',
    scrollbarTrack: '#F5F5F5',
    scrollbarThumb: '#CCCCCC',
    contextTargetingDefault: '#4BA5F8',
    contextTargetingHover: '#357AB9',
    contextTargetingDisable: '#ACABB7',
    contextTargetingDisableLabel: '#636268',
    creativeManagementBorderColor: '#EAEFF8',
    creativeManagementeSubHeaderColor: '#05A8F1',
    creativeManagementButtonColor: '#2D9BF0',
    creativeManagementSubmitColor: '#0500A9',

    // Rebrand New colors
    broadsignBlue: '#001464',
    broadsignNavBlueHover: 'rgba(255, 255, 255, 0.20)',
    broadsignNavDropdownHover: '#E6E5F8',
    broadsignNavDropdownTitle: '#303030',
    borderLightGrey: '#CFCED6',
    broadsignNavDropdownHoverTitle: '#0500A9',
    tagAvailable: '#4CA57D',
    tagPartiallyAvailable: '#B87902',
    tagUnavailable: '#B00020',
    fillRateBarColor: '#6279FF',
    inventorySettingsBackground: '#F2F2F5',
    inventoryFilterCheckboxUnchecked: '#636268',
    inventoryFilterBorders: '#ACABB7',
    inventorySummaryBackground: '#F2F2F5',
    inventorySummaryBlockBorderColor: '#D2D2D2',
    inventorySummaryBlockTextColor: '#404040',
    inventorySummaryGroupCountTextColor: '#36492A',
    bookingLevelsTitleColor: '#2C2C2C',
    bookingLevelsHeldColumnColor: '#ACABB7',
    bookingLevelsBookedColumnColor: '#001464',
    niagara: '#08A87B',
    mischka: '#d9d8df',
    peacock: '#1056FB',
    periwinkleGray: '#CEDAED',
    bostonBlue: '#357AB9',
    silverChalice: '#A6A6A6',
    alto: '#E0E0E0',
    alabastersolid: '#FAFAFA',
};

module.exports = colors;
